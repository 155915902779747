<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>大数据管理</el-breadcrumb-item>
            <el-breadcrumb-item>人才简历库</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box" v-loading="loading">
            <div class="topchange">
                <el-input size="small" v-model="num" placeholder="请输入一个数字" style="width:200px;margin-right:5px;"  onkeyup="value=value.replace(/[^\d\.]/g,'')" ></el-input><el-button type="primary" size="small" @click="changedata">更改数据</el-button><el-button type="primary" size="small" @click="resetdata">重置数据</el-button>
            </div>
            <div class="list" v-for="item in list">
                <div class="listtitle"><strong>{{item.title}}</strong><el-button type="primary" size="mini" style="float:right;" @click="openadd(item.id)">+添加记录</el-button></div>
                <div class="listitem">
                    <ul>
                        <li v-for="sonitem in item.personnel"><span>{{sonitem.property}}</span><em>{{sonitem.propertyValue}}</em><p><i @click="editopen(sonitem)">编辑</i><i @click="deleteitem(sonitem.id)">删除</i></p></li>
                    </ul>
                </div>
            </div>
        </div>
        <pop-up title="添加" width="26%" :isshow="addshow" :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="addfrom" :model="addfrom" label-width="40px">
                    <el-form-item label="名称">
                        <el-input v-model="addfrom.property"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="addfrom.propertyValue"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
        <pop-up title="编辑" width="26%" :isshow="editshow" :haveconfirm="true" @close="getclose2" @confirm="getconfirm2">
            <div slot="box">
                <el-form ref="addfrom" :model="editfrom" label-width="40px">
                    <el-form-item label="名称">
                        <el-input v-model="editfrom.property"></el-input>
                    </el-form-item>
                    <el-form-item label="值">
                        <el-input v-model="editfrom.propertyValue"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
import serverurl from '@/api/bigdata'
import _api from '@/api/index'
export default {
    data(){
        return{
            num:"",
            list:[],
            loading:false,
            addshow:false,
            editshow:false,
            addfrom:{
                type:"",
                property:"",
                propertyValue:""
            },
            editfrom:{
                id:"",
                property:"",
                propertyValue:""
            }
        }
    },
    created(){
        this.getList()
    },
    methods:{
        resetdata(){
            _api.get(serverurl.reset1,{}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("重置成功！")
                    this.getList()
                }
            })
        },
        changedata(){//更改数据
            if(this.num==""){
                this.$message.error("请输入一个变换系数")
                return
            }
            _api.get(serverurl.random1,{num:this.num}).then(res=>{
                console.log(res)
                if(res.success){
                    this.$message.success("更改成功！")
                    this.getList()
                }
            })
        },
        deleteitem(id){
            _api.get(serverurl.deleteitem,{id:id}).then(res=>{
                if(res.success){
                    this.$message.success("删除成功！")
                    this.getList()
                }
            })
        },
        editopen(row){
            this.editshow=true
            this.editfrom.id=row.id
            this.editfrom.property=row.property
            this.editfrom.propertyValue=row.propertyValue
        },
        getList(){
            this.loading=true
            _api.post(serverurl.bigType,{}).then(res=>{
                console.log(res)
                this.loading=false
                this.list=res.data
            })
        },
        openadd(id){
            this.addshow=true
            this.addfrom.type=id
            
        },
        getclose(){
            this.addshow=false
        },
        getconfirm(){
            
            _api.post(serverurl.insertAndEdit,this.addfrom).then(res=>{
                if(res.success){
                    this.addshow=false
                    this.getList()
                    this.$message.success("添加成功！")
                    this.addfrom.type=""
                    this.addfrom.property=""
                    this.addfrom.propertyValue=""
                }
            })
        },
        getclose2(){
            this.editshow=false
        },
        getconfirm2(){
            _api.post(serverurl.insertAndEdit,this.editfrom).then(res=>{
                if(res.success){
                    this.editshow=false
                    this.$message.success("编辑成功！")
                    this.getList()
                    this.editfrom.id=""
                    this.editfrom.property=""
                    this.editfrom.propertyValue=""
                }
            })
        }
    }
}
</script>
<style scoped>
@import "../../assets/css/bigdata.css";
.topchange{ text-align: center; padding-bottom: 20px;}
</style>